import Link from 'next/link'

export default function FourHundredFour() {
	return (
		<main className="min-h-full bg-tmblue">
			<div className="mx-auto max-w-7xl px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
				<h1 className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">Uh oh! I think you’re lost 📡</h1>
				<p className="mt-2 text-lg font-medium text-white text-opacity-50">It looks like the page you&apos;re looking for doesn&apos;t exist.</p>
				<div className="mt-6">
					<Link href="/" className="inline-flex items-center rounded-md border border-transparent bg-white bg-opacity-25 px-4 py-2 text-sm font-medium text-white text-opacity-75 hover:bg-opacity-50">
						Go back home
					</Link>
				</div>
			</div>
		</main>
	)
}
